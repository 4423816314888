<template>
<!--  充电桩使用情况-->
  <div id="chartDom" ref="chart"></div>
</template>

<script>
import {getCurrentInstance, onMounted} from "vue-demi";
import {reactive, toRefs, watch} from "vue";
import * as echarts from 'echarts'

export default {
  name: "ChargingPilePie",
  props: {
    totalData: {
      type: Array,
      default () {
        return []
      }
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      virtualData: [2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,
        2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,
        2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,],
      data: [],
      totalQuantity: 0,
      name:''
    })

    onMounted(() => {
      chargingPilePie()
    })

    watch(
        () => props.totalData,
        () => {
          // console.log(props.totalData, 'pie')
          if (props.totalData.length > 0) {
            data.data = props.totalData
            for (let i = 0; i < data.data.length; i++) {
              if (data.data[i].name === '已使用' || data.data[i].name === '未使用') {
                const num = data.data[0].value
                data.totalQuantity = num + '%'
                data.name = '插座使用率'
              } else {
                data.totalQuantity += data.data[i].value
                data.name = '总数'
              }

            }
          }
          chargingPilePie()
        },
        {
          deep: true,
        }
    )

    const chargingPilePie = () => {
      const chartDom = proxy.$refs.chart
      if (chartDom) {
        let myChart = echarts.init(chartDom)
        myChart.option ={
          title: {
            zlevel: 0,
            text: [
              '{value|' + data.totalQuantity + '}',
              '{name|' + data.name + '}',
            ].join('\n'),
            rich: {
              value: {
                color: '#303133',
                fontSize: 23,
                fontWeight: 'bold',
                lineHeight: 23,
              },
              name: {
                color: '#909399',
                lineHeight: 23
              },
            },
            // top: '90',
            // left: '190',
            x: '49%',
            y: '40%',
            textAlign: 'center',
            textStyle: {
              rich: {
                value: {
                  color: '#fff',
                  fontSize:23,
                  fontWeight: 'bold',
                  lineHeight: 23,
                },
                name: {
                  color: '#fff',
                  lineHeight: 23
                },
              },
            },
          },
          tooltip: {
            trigger: 'item',
            // formatter: "{b} : {c} ({d}%)"
          },
          legend: { // 图例组件
            bottom: 1,
            left: 'center',
            icon: 'circle',
            textStyle: {
              color: '#BBBBBB',
              fontSize: '12',
            }
          },
          grid: {
            top:70
          },
          color: [
            {
              type: 'linear',
              // x: 0,
              // y: 0,
              // x2: 0,
              // y2: 1,
              colorStops: [{
                offset: 0, color: 'rgba(34, 231, 229, 1)' // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(87, 133, 245, 1)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            },{
              type: 'linear',
              colorStops: [{
                offset: 0, color: 'rgba(250, 217, 97, 1)' // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(247, 120, 36, 1)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            },{
              type: 'linear',
              colorStops: [{
                offset: 0, color: 'rgba(233, 61, 112, 1)' // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(253, 128, 96, 1)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            },{
              type: 'linear',
              colorStops: [{
                offset: 0, color: 'rgba(40, 211, 139, 1)' // 0% 处的颜色
              }, {
                offset: 1, color: 'rgba(0, 255, 196, 1)' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            },
          ],
          series: [
            {
              // 内层圆环
              type: 'pie',
              radius: ['40%', '45%'],
              color:['#29424b', '#1c2f36c4'],
              data: data.virtualData,
              labelLine: {
                show: false
              },
              legend: { // 图例组件
               show: false
              },
              tooltip: {
                show: false
              },
              silent: { //响应和触发鼠标事件
                show: false
              }
            },
            {
              type: 'pie',
              radius: ['50%', '57%'],
              itemStyle: {
                borderRadius: 10,
                borderWidth: 2,
                shadowColor: 'rgba(253,253,253,0.5)',
                shadowBlur: 3,
              },
              labelLine: {
                show: false
              },
              // 鼠标悬停时显示的label也显示在饼图中间
              zlevel: 1,
              label: {
                normal: {
                  padding: [4, 4, 4, 4],
                  // backgroundColor: '#fff',
                  show: false,
                  position: 'center',
                  formatter: 130,
                  // formatter: [
                  //   '{value|{c}}',
                  //   '{name|{b}}'
                  // ].join('\n'),
                  // rich: {
                  //   value: {
                  //     color: '#FFFFFF',
                  //     fontSize: 24,
                  //     fontWeight: 'bold',
                  //     lineHeight: 24,
                  //   },
                  //   name: {
                  //     color: '#909399',
                  //     lineHeight: 15
                  //   },
                  // },
                },
                // emphasis: {
                //   show: true,
                //   textStyle: {
                //     fontSize: '10',
                //     fontWeight: 'bold'
                //   }
                // }
              },
              data: data.data
            }
          ],
        }
        myChart.option && myChart.setOption(myChart.option)
      }
    }

    return {
      ...toRefs(data),
      chargingPilePie
    }
  }
}
</script>

<style lang="less" scoped>
#chartDom {
  width: 20vw;
  height: 25vh;
}
</style>
