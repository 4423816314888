import { axios } from '@/utils/request'

const api = {
    getToDayDevice: 'charging/analysis/getToDayDevice', // 今天/昨天-设备总数
    getToDayUser: 'charging/analysis/getToDayUser', // 今天/昨天-用户总数
    byMerchantId: 'device/device/ByMerchantId', // 根据merchantId查询商户下所有充电站设备
    getToDay: 'charging/analysis/getToDay', // 今天/昨天-营业额/订单数/充电用户
    getWeekDay: 'charging/analysis/getWeekDay', // 近7天的营业额/订单数/充电用户
    getweekDayIncome: 'charging/analysis/getWeekDayIncome',     // 近7天收入
    getToDayRefill: 'charging/analysis/getToDayRefill', // 今天/昨天-充值金额
    getWeekDayRefill: 'charging/analysis/getWeekDayRefill', // 近7天的充值金额
    getSumDevice: 'charging/analysis/getSumDevice', //当前设备总数-在线/离线
    getOccupyDevice: 'charging/analysis/getOccupyDevice', //当前插座总数
}
export default api

export const getToDayDeviceApi = (params) => {
    return axios({
        url: api.getToDayDevice,
        method: 'post',
        params
    })
}

export const getToDayUserApi = (params) => {
    return axios({
        url: api.getToDayUser,
        method: 'post',
        params
    })
}

export const byMerchantIdApi = (params) => {
    return axios({
        url: api.byMerchantId,
        method: 'post',
        params
    })
}

export const getToDayApi = (params) => {
    return axios({
        url: api.getToDay,
        method: 'post',
        params
    })
}

export const getWeekDayApi = (params) => {
    return axios({
        url: api.getWeekDay,
        method: 'post',
        params
    })
}

export const getweekDayIncomeApi = (params) => {
    return axios({
        url: api.getweekDayIncome,
        method: 'post',
        params
    })
}

export const getToDayRefillApi = (params) => {
    return axios({
        url: api.getToDayRefill,
        method: 'post',
        params
    })
}

export const getWeekDayRefillApi = (params) => {
    return axios({
        url: api.getWeekDayRefill,
        method: 'post',
        params
    })
}

export const getSumDeviceApi = (params) => {
    return axios({
        url: api.getSumDevice,
        method: 'post',
        params
    })
}

export const getOccupyDeviceApi = (params) => {
    return axios({
        url: api.getOccupyDevice,
        method: 'post',
        params
    })
}
